/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.4.0-v202412232045-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqSCIMApiClass {
 
   constructor() {}

  /**
   * @summary Generate a new SCIM authentication token for the specified datasource
   * @param {string} datasourceClass - The datasource class of the user&#x27;s directory
   * @param {string} datasourceId - The datasource ID of the user&#x27;s directory
   */
  public generateToken(
    body: models.ScimTokenInputV1,
    {
      datasourceClass,
      datasourceId
    } : {
      datasourceClass: string,
      datasourceId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/scim/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/token`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ScimTokenOutputV1>;
  }

  /**
   * @summary Retrieve the SCIM authentication token expiration information for the specified datasource
   * @param {string} datasourceClass - The datasource class of the user&#x27;s directory
   * @param {string} datasourceId - The datasource ID of the user&#x27;s directory
   */
  public getToken(
    {
      datasourceClass,
      datasourceId
    } : {
      datasourceClass: string,
      datasourceId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/scim/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/token`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ScimTokenOutputV1>;
  }

  /**
   * @summary Invalidates all SCIM tokens for the specified datasource
   * @param {string} datasourceClass - The datasource class of the user&#x27;s directory
   * @param {string} datasourceId - The datasource ID of the user&#x27;s directory
   */
  public invalidateToken(
    {
      datasourceClass,
      datasourceId
    } : {
      datasourceClass: string,
      datasourceId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/scim/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/token`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

}


export const sqSCIMApi = new sqSCIMApiClass();
