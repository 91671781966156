// @ts-strict-ignore
import { DateTimeRange, Duration, ViewDateRange } from '@/vantage/types/DateTime.types';
import dayjs, { Dayjs } from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { DurationJSON } from './types/TableConfig.types';
import { sqWorkbenchStore } from '@/core/core.stores';
import { startOfWeek, format, Locale, parse } from 'date-fns';
import { t } from 'i18next';
import { DURATION_TIME_UNITS_ALL } from '@/main/app.constants';

dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);

export function getNow(timeZone: any) {
  return timeZone?.offset ? dayjs().utcOffset(timeZone.offsetMinutes) : dayjs();
}

export function hasDateRangeChanged(loadedViewDateRange: ViewDateRange, activeViewDateRange: ViewDateRange): boolean {
  if (!loadedViewDateRange || !activeViewDateRange) {
    return false;
  }
  const sameLookBack = _.isEqual(loadedViewDateRange.lookBack, activeViewDateRange.lookBack);
  const sameLookForward = _.isEqual(loadedViewDateRange.lookForward, activeViewDateRange.lookForward);
  const sameNone = loadedViewDateRange.none === activeViewDateRange.none;
  const relativeTime =
    (activeViewDateRange.lookForward !== undefined && activeViewDateRange.lookForward?.num !== 0) ||
    (activeViewDateRange.lookBack !== undefined && activeViewDateRange.lookBack?.num !== 0) ||
    (activeViewDateRange.none !== undefined && activeViewDateRange.none);
  const sameRange =
    dayjs(loadedViewDateRange.range[0]).isSame(dayjs(activeViewDateRange.range[0])) &&
    dayjs(loadedViewDateRange.range[1]).isSame(dayjs(activeViewDateRange.range[1]));

  return !(sameLookBack && sameLookForward && sameNone && (relativeTime || sameRange));
}

export function formatDate(date: Date, format: string, timeZone: any | null = null): string {
  return timeZone ? dayjs.tz(date, timeZone.name).format(format) : dayjs(date).format(format);
}

export function dateTimeFormatter(date: Date): string {
  const dateFnsLocal: Locale = sqWorkbenchStore.dateFnsLocale;
  return format(date, 'PPp', { locale: dateFnsLocal });
}

export function toZonedISOString(dateTime: string): string {
  const dt = parse(dateTime, 'PPp', Date.now(), { locale: sqWorkbenchStore.dateFnsLocale });
  const zonedDateTime = dayjs(dt).tz(sqWorkbenchStore.timezone.name, true);
  return zonedDateTime.format();
}

export function getCalendarTime(date: Date, timeZone): string {
  const localDatetime = dayjs(date).tz(timeZone.name);
  const is24Hour = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hour12 === false;

  const timeFormat = is24Hour ? 'HH:mm' : 'h:mm A';
  const dateFormat = new Intl.DateTimeFormat(navigator.language).format(new Date(date));

  const calendarRepresentation = {
    sameDay: `[Today at] ${timeFormat}`,
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: `[Yesterday at] ${timeFormat}`,
    lastWeek: '[Last] dddd',
    sameElse: `${dateFormat}, ${timeFormat}`,
  };

  return localDatetime.calendar(null, calendarRepresentation);
}

export function getHumanizedDuration(duration: Duration): string {
  return dayjs.duration(duration.num, duration.unit.value as DurationUnitType).humanize();
}

export function getDurationAsMilliseconds(duration: Duration): number {
  return dayjs.duration(duration.num, duration.unit.value as DurationUnitType).asMilliseconds();
}

export const isoToLocalTimeZone = (dateIso, timeZone: any) => {
  const localDatetime = isoToLocalTimeZoneDate(dateIso, timeZone);

  try {
    return `${dateTimeFormatter(localDatetime)} (${timeZone.displayName})`;
  } catch (e) {
    return 'invalid-date';
  }
};

export const isoToLocalTimeZoneDate = (dateIso, timeZone: any): Date => {
  const datetime = new Date(dateIso);
  if (Number.isNaN(datetime.getTime())) {
    console.warn(
      `there was an error converting the date ${dateIso} to local time zone; returning the value without conversion`,
    );
    return dateIso;
  }

  return new Date(datetime.toLocaleString('en-US', { timeZone: timeZone.name }));
};

const roundToNearest = (x, precision) => Math.round(x / precision) * precision;

export const roundToNearestHalfHour = (date: Dayjs): Dayjs => {
  const minutes = date.minute();
  return date.minute(roundToNearest(minutes, 30)).second(0).millisecond(0);
};

// This mess is because when we set the offset minutes (or timezone) in dayjs, it doesn't change the timezone the
// object is reprsented in internally, but doing toDate() uses the offset to re-calculate the time. So we have to do
// a bunch of conversions to get the correct hour in the user's timezone
// Exporting for testing purposes
type relativeHour = {
  hour: number;
  nextDay: boolean;
};
export const getHourinUserTimeZone = (date: Dayjs, timeZone: any): relativeHour => {
  const newHour = date.hour() + date.toDate().getTimezoneOffset() / 60 + timeZone.offsetMinutes / 60;
  if (newHour > 24) {
    return { hour: newHour - 24, nextDay: true };
  } else {
    return { hour: newHour, nextDay: false };
  }
};

export const getStartOfRelativeRange = (timeZone: any, now: Dayjs, lookBack: Duration): Dayjs => {
  if (lookBack === undefined || lookBack.num === 0) {
    return now;
  }

  const tempLookBack = now.subtract(lookBack.num, lookBack.unit.value as DurationUnitType);

  if (lookBack.unit.value === 'hour') {
    const calculatedHour = getHourinUserTimeZone(tempLookBack, timeZone);
    if (calculatedHour.nextDay) {
      return tempLookBack
        .set('day', tempLookBack.day() + 1)
        .set('hour', calculatedHour.hour)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);
    } else {
      return tempLookBack.set('hour', calculatedHour.hour).set('minute', 0).set('second', 0).set('millisecond', 0);
    }
  }
  return dayjs(tempLookBack).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
};

export const getEndOfRelativeRange = (timeZone: any, now: Dayjs, lookForward: Duration): Dayjs => {
  if (lookForward === undefined || lookForward.num === 0) {
    return now;
  }

  const tempLookForward = now.add(lookForward.num, lookForward.unit.value as DurationUnitType);

  if (lookForward.unit.value === 'hour') {
    const calculatedHour = getHourinUserTimeZone(tempLookForward, timeZone);
    if (calculatedHour.nextDay) {
      return tempLookForward
        .set('day', tempLookForward.day() + 1)
        .set('hour', calculatedHour.hour)
        .set('minute', 59)
        .set('second', 59);
    } else {
      return tempLookForward.set('hour', calculatedHour.hour).set('minute', 59).set('second', 59);
    }
  }
  return dayjs(tempLookForward).set('hour', 23).set('minute', 59).set('second', 59);
};

export const getResultOfRelativeRange = (range: ViewDateRange, timeZone: any): DateTimeRange => {
  const now = getNow(timeZone);

  return [
    getStartOfRelativeRange(timeZone, now, range.lookBack).toDate(),
    getEndOfRelativeRange(timeZone, now, range.lookForward).toDate(),
  ];
};

export const durationMillisecondsToString = (time_milliseconds: number) => {
  const oneDayMilliseconds = 86400000;
  if (time_milliseconds < oneDayMilliseconds) {
    return dayjs.duration(time_milliseconds).format('HH:mm:ss');
  } else {
    const totalDays = Math.trunc(time_milliseconds / oneDayMilliseconds);
    const remainderMilliseconds = time_milliseconds % oneDayMilliseconds;
    const remainder = dayjs.duration(remainderMilliseconds).format('HH:mm:ss');
    return `${totalDays} ${t('UNITS.DAYS', { count: totalDays })} ${remainder}`;
  }
};

export const isValidDateRange = (range: DateTimeRange) => {
  return !dayjs(range[0]).isSameOrAfter(dayjs(range[1]));
};

export const stringToDuration = (stringDuration: string) => {
  const regex = /^(\d+)\s*(hours?|days?|h|d)$/i;
  const match = stringDuration.match(regex);

  if (!match) {
    console.error('Invalid duration string for default date range.');
    return undefined;
  }

  const num = Number(match[1]);
  const unit = match[2].toLowerCase()[0];

  const unitConversion = {
    h: 1,
    d: 24,
  };

  return num * unitConversion[unit];
};

export function getDefaultDateRange(defaultDateRange: DurationJSON, timeZone: any): DateTimeRange {
  let lookback = 24;
  let lookahead = 0;

  if (defaultDateRange?.lookback) {
    const parsedLookback = stringToDuration(defaultDateRange.lookback);
    if (parsedLookback !== undefined) {
      lookback = parsedLookback;
    } else {
      console.error(`Invalid lookback duration. Using default of ${lookback} hours`);
    }
  }

  if (defaultDateRange?.lookahead) {
    const parsedLookahead = stringToDuration(defaultDateRange.lookahead);
    if (parsedLookahead !== undefined) {
      lookahead = parsedLookahead;
    } else {
      console.error(`Invalid lookahead duration. Using default of ${lookahead} hours`);
    }
  }

  return [getNow(timeZone).subtract(lookback, 'hours').toDate(), getNow(timeZone).add(lookahead, 'hours').toDate()];
}

export function coerceFromBrowserToSeeqTimezone(originalTime, timeZone) {
  const original = new Date(originalTime).getTime();
  const browserTimeZoneOffset = new Date(originalTime).getTimezoneOffset() * 60 * 1000;
  const seeqTimeZoneOffset = timeZone.offsetMinutes * 60 * 1000;
  const correctedTime = original - browserTimeZoneOffset - seeqTimeZoneOffset;
  return new Date(correctedTime);
}

export function coerceFromSeeqToBrowserTimezone(originalTime, timeZone) {
  const original = new Date(originalTime).getTime();
  const browserTimeZoneOffset = new Date(originalTime).getTimezoneOffset() * 60 * 1000;
  const seeqTimeZoneOffset = timeZone.offsetMinutes * 60 * 1000;
  const correctedTime = original + browserTimeZoneOffset + seeqTimeZoneOffset;
  return new Date(correctedTime);
}

const getLocalizedDate = (options: Intl.DateTimeFormatOptions) => {
  const userLocale = navigator.language;
  return (date: Date) => new Intl.DateTimeFormat(userLocale, options).format(date);
};

export const GRANULARITY_OPTIONS = {
  day: {
    translationKey: 'VANTAGE.EVIDENCE_TABLE.TIME_SETTINGS.FIELD.TIME_GRANULARITY.DAY',
    formatter: () =>
      getLocalizedDate({
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  week: {
    translationKey: 'VANTAGE.EVIDENCE_TABLE.TIME_SETTINGS.FIELD.TIME_GRANULARITY.WEEK',
    formatter: () => (date: Date) => {
      const weekStart = startOfWeek(date, { locale: sqWorkbenchStore.dateFnsLocale });
      const formatter = getLocalizedDate({
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return `${t('VANTAGE.EVIDENCE_TABLE.TIME_SETTINGS.FIELD.TIME_GRANULARITY.WEEK_OF')} ${formatter(weekStart)}`;
    },
  },
  month: {
    translationKey: 'VANTAGE.EVIDENCE_TABLE.TIME_SETTINGS.FIELD.TIME_GRANULARITY.MONTH',
    formatter: () => (date: Date) => format(date, 'LLLL yyyy', { locale: sqWorkbenchStore.dateFnsLocale }),
  },
  year: {
    translationKey: 'VANTAGE.EVIDENCE_TABLE.TIME_SETTINGS.FIELD.TIME_GRANULARITY.YEAR',
    formatter: () => (date: Date) => format(date, 'yyyy', { locale: sqWorkbenchStore.dateFnsLocale }),
  },
};
